import React from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import "./Browse.css";
import letterMap from "./GetBrowse";

const Browse = () => {
  const { letter, page } = useParams();

  const divider = 100;

  let wordsList = [];
  let pageCount = 0;

  const alphabets = "abcdefghijklmnopqrstuvwxyz".split("");

  wordsList = letterMap[letter].slice((page - 1) * divider, page * divider + 1);
  pageCount = Math.ceil(letterMap[letter].length / divider);

  return (
    <div className="browse">
      <h1>Browse Dictionary</h1>
      <br />
      <div className="alphabets">
        {alphabets.map((alpha) => {
          return (
            <Link to={`/browse/${alpha}/1`} key={alpha} className="link">
              {alpha.toUpperCase()}
            </Link>
          );
        })}
      </div>
      {
        <div className="word-list">
          {wordsList.map((word) => {
            return (
              <ul key={word}>
                <Link to={"/word/" + word} className="link">
                  {word}
                </Link>
              </ul>
            );
          })}
        </div>
      }
      <div className="pages">
        {Array(pageCount)
          .fill()
          .map((_, i) => i + 1)
          .map((p) => {
            return (
              <Link
                to={`/browse/${letter}/${p}`}
                key={p}
                className="link"
                style={{
                  pointerEvents: p === parseInt(page) ? "none" : "auto",
                  textDecoration: p === parseInt(page) ? "none" : "underline",
                }}
              >
                {p}
              </Link>
            );
          })}
      </div>
      <br />
    </div>
  );
};

export default Browse;
