import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "./App.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Body from "./components/Body";
import About from "./components/About";
import AutoSuggest from "./components/AutoSuggest";
import WordsOfTheDay from "./components/WordsOfTheDay";
import ReactGA from "react-ga4";
import DownloadApp from "./components/DownloadApp";
import Browse from "./components/Browse";
import { Helmet } from "react-helmet";
import NotFound from "./components/NotFound";

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);

function App() {

  const location = useLocation();

  const [searchedWord, setSearchedWord] = useState("");

  const HOME_URL = process.env.REACT_APP_HOME_URL;
  const APP_LANGUAGE = process.env.REACT_APP_LANGUAGE;

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });

    if (location.pathname.startsWith("/word/")) {
      let word = location.pathname.split("/")[2];
      setSearchedWord(word);
    } else if (location.pathname.startsWith("/")) {
      document.title = process.env.REACT_APP_APP_TITLE;
      setSearchedWord("");
    }
  }, [location.pathname]);

  return (
    <div className="App">
      <Helmet>
        <title>
          {searchedWord.length > 0
            ? `${searchedWord} meaning, definition in ${APP_LANGUAGE.toLowerCase()} - ${APP_LANGUAGE} Dictionary`
            : `${APP_LANGUAGE} Dictionary - ${APP_LANGUAGE} Meaning, definition & Translation`}
        </title>
        <meta
          name="description"
          content={`Find the ${APP_LANGUAGE.toLowerCase()} meaning, defintion of ${searchedWord}. Learn how to use with examples. Know synonyms and antonyms of ${searchedWord}.`}
        />
        <link rel="canonical" href={`${HOME_URL}/${searchedWord}`} />
      </Helmet>
      <Header />
      <AutoSuggest />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <WordsOfTheDay />
            </>
          }
        />
        <Route path="/browse" element={<Navigate to="/browse/a/1" replace />} />
        <Route path="/browse/:letter/:page" element={<Browse />} />
        <Route path="/word" element={<Navigate to="/" replace />} />
        <Route
          path="/word/:searchedWord"
          element={
            <>
              <Body />
            </>
          }
        />

        <Route path="/about" element={<About />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <DownloadApp />
      <Footer />
    </div>
  );
}

export default App;
