import Trie from "./trie";
import ALL_DICTIONARY_WORDS from "../dic";

let trie = new Trie();
for (const word of ALL_DICTIONARY_WORDS) {
  trie.add(word);
}

const getAutoSuggestions = (word) => {
  trie.clear();
  let suggestions = [];
  if (ALL_DICTIONARY_WORDS.includes(word)) {
    suggestions.push(word);
  }
  suggestions = suggestions.concat(trie.complete(word, 10));
  return suggestions;
};

export default getAutoSuggestions;
