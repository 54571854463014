import ALL_DICTIONARY_WORDS from "../dic";

let letterMap = {};
for (const word of ALL_DICTIONARY_WORDS) {
  let char = word.charAt(0);
  if (!(char in letterMap)) {
    letterMap[char] = [];
  }
  letterMap[char].push(word);
}

export default letterMap;
