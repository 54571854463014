import { React } from "react";
import "./SpellingSuggestion.css";
import getSpellingSuggestions from "./SpellSuggestionGenerator";

const SpellingSuggestions = ({ word }) => {
  const getNewUrl = (_word) => {
    if (window.location.pathname.startsWith("/word")) {
      return _word;
    }
    return "/word/" + _word;
  };

  let spellingSuggestions = getSpellingSuggestions(word);

  return (
    <div className="spelling-container">
      {
        <p>
          No search Result for <strong>{word}</strong>
        </p>
      }
      <div className="spellings">
        {spellingSuggestions.map((item) => {
          return (
            <a href={getNewUrl(item)} key={item}>
              {item}
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default SpellingSuggestions;
