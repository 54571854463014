const WORDS_OF_THE_DAY_LIST = [
  "zenith",
  "vent",
  "verdict",
  "Placate",
  "Insipid",
  "Placid",
  "Lucid",
  "Plagiarize",
  "Verbose",
  "Protagonist",
  "Misogynous",
  "Sycophant",
  "Nuance",
  "Nascent",
  "Empathy",
  "Efficacy",
  "Melancholy",
  "Amicable",
  "Incessant",
  "Ardent",
  "Equivocal",
  "Obdurate",
  "Surreal",
  "Tenuous",
  "Ostracize",
  "Acrid",
  "Indolent",
  "Vitriol",
  "Caprice",
  "Tactile",
  "Austerity",
  "Abysmal",
  "Paradox",
  "Insolent",
  "Juxtapose",
  "celibate",
  "benevolent",
  "palpable",
  "quell",
  "verdict",
  "vendatta",
  "referendum",
  "stubborn",
  "relent",
  "reluctant",
  "eloquent",
  "tyranny",
  "eavesdrop",
  "eon",
  "eptiome",
  "docile",
  "clandestine",
  "innocent",
  "indolent",
  "indigent",
  "mediocre",
  "exapserate",
  "indignant",
  "retort",
  "stout",
  "bona fide",
  "insolent",
  "nuance",
  "gruesome",
  "brutal",
  "annex",
  "soothe",
  "drool",
  "lunatic",
  "prowess",
  "hindrance",
  "abate",
  "empathy",
  "impute",
  "mundane",
  "nonchalant",
  "opulent",
  "prudent",
  "reverence",
  "scrutinize",
  "subtle",
  "tenacious",
  "vindicate",
  "wary",
  "abhor",
  "abstain",
  "compassion",
  "diligent",
  "disdain",
  "brusque",
  "coalesce",
  "conceal",
  "contemplate",
  "dilemma",
  "elite",
  "endure",
  "famine",
  "hostile",
  "intrepid",
  "intricate",
  "lenient",
  "mediocre",
  "morose",
  "nonchalant",
  "pragmatic",
  "progeny",
  "resent",
  "rhetorical",
  "scrutinize",
  "severe",
  "stagnant",
  "vestige",
  "vial",
  "vilify",
  "wilt",
  "atone",
  "endeveor",
  "extradite",
  "virtue",
  "quench",
  "ire",
  "obsess",
  "obese",
  "wile",
  "shatter",
  "strive",
  "succumb",
  "vanity",
  "vehemence",
  "vigilance",
  "vindicate",
  "pervade",
  "nausea",
  "extol",
  "Berate",
  "Bestow",
  "Coalesce",
  "Insipid",
  "Arduous",
  "Orthodox",
  "Pellucid",
  "Placate",
  "Deride",
  "Pedant",
  "Euphoric",
  "Platonic",
  "Ambivalent",
  "ambidextrous",
  "Equivocate",
  "Fervid",
  "Trivial",
  "Meticulous",
  "Succinct",
  "Plausible",
  "Eulogy",
  "Benign",
  "Imminent",
  "Obdurate",
  "Ostentation",
  "Copious",
  "Obscure",
  "Esoteric",
  "Redundant",
  "Ameliorate",
  "Prosaic",
  "Arduous",
  "Insipid",
  "Laconic",
  "philanthropist",
  "introvert",
  "malevolent",
  "stray",
  "suffocate",
  "affinity",
  "animosity",
  "fragile",
  "massacre",
  "conceal",
  "indigenous",
  "grumble",
  "edible",
  "timid",
  "abscond",
  "brittle",
  "salvage",
  "livid",
  "rejuvenation",
  "dullard",
  "dwarf",
  "narcissism",
  "commuter",
  "pillon",
  "benefactor",
  "plea",
  "invincible",
  "turmoil",
  "sanity",
  "dormant",
  "gallant",
  "shard",
  "hypocrisy",
  "chisel",
  "misogynist",
  "abysmal",
  "dupe",
  "connoisseur",
  "abstruse",
  "limp",
  "aberration",
  "paradigm",
  "gullible",
  "sobriety",
  "immutable",
  "cordial",
  "stride",
  "indulgent",
  "bogus",
  "dexterity",
  "feud",
  "reverent",
  "valiant",
  "imminent",
  "hoax",
  "counterfeit",
  "torment",
  "lustrous",
  "extol",
  "illicit",
  "inept",
  "malevolence",
  "impediment",
  "felon",
  "bigot",
  "dismal",
  "lavish",
  "posh",
  "castigation",
  "indulge",
  "extrovert",
  "astute",
  "cuddle",
  "ominous",
  "ostracism",
  "quell",
  "extort",
  "plead",
  "chaste",
  "morbid",
  "breach",
  "daunt",
  "benign",
  "impiety",
  "guile",
  "ardor",
  "terse",
  "abeyance",
  "myriad",
  "clinch",
  "tepid",
  "quack",
  "emaciate",
  "quixotic",
  "profuse",
  "savant",
  "vain",
  "puerile",
  "glut",
  "sting",
  "obtuse",
  "onus",
  "vanquish",
  "auspicious",
  "secular",
  "dearth",
  "cogent",
  "lurk",
  "condone",
  "inane",
  "abjure",
  "inundate",
  "avid",
  "facile",
  "quirk",
  "foil",
  "discourse",
  "hectic",
  "tenure",
  "eschew",
  "rant",
  "plethora",
  "forge",
  "hone",
  "vigilant",
  "resort",
  "incense",
  "blatant",
  "venal",
  "fugitive",
  "ambiguous",
  "entice",
  "alleviate",
  "adorn",
  "abhor",
  "suffocate",
  "legacy",
  "adulteration",
  "adultery",
  "appease",
  "apt",
  "extinguish",
  "accolade",
  "console",
  "abide",
  "abscond",
  "embezzle",
  "reminiscent",
  "shallow",
  "brittle",
  "affable",
  "sluggard",
  "defer",
  "plea",
  "turmoil",
  "volatile",
  "dormant",
  "misery",
  "earthenware",
  "warmonger",
  "divine",
  "longevity",
  "blessing",
  "incarnation",
  "devotee",
  "swear",
  "atheist",
  "discretion",
  "rage",
  "contempt",
  "loathe",
  "mercy",
  "blashphemy",
  "cajole",
  "amicable",
  "benevolent",
  "Elucidate",
  "Futile",
  "Veracity",
  "Sardonic",
  "Abstruse",
  "Obfuscate",
  "Abrogate",
  "Sycophant",
  "Tepid",
  "Labyrinth",
  "Voracious",
  "Corpulent",
  "Docile",
  "Dismal",
  "Insular",
  "Frugal",
  "Dubious",
];

export default WORDS_OF_THE_DAY_LIST;
