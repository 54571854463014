import React from "react";
import "./NotFound.css";

const NotFound = () => {
  return (<div className="notfound-container">
    <h1>404 - Page Not Found</h1>
    <br />
    <p>Sorry, the page you are looking for does not exist.</p>
  </div>);
};

export default NotFound;