import React from "react";
import "./WordOfTheDay.css";
import { Link } from "react-router-dom";
import WORDS_OF_THE_DAY_LIST from "../wordOfTheDayList";

const WordsOfTheDay = () => {
  let wotd;
  let todayDate;

  const MONTHS = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];

  const DAYS = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

  var currentDate = new Date();

  todayDate = `${DAYS[currentDate.getDay()]}, ${currentDate.getDate()} ${
    MONTHS[currentDate.getMonth()]
  } ${currentDate.getFullYear()}`;
  let diffInMilSeconds = new Date() - new Date(2021, 12, 12);
  let diff = Math.floor(diffInMilSeconds / (1000 * 60 * 60 * 24));

  diff = diff % WORDS_OF_THE_DAY_LIST.length;
  wotd = WORDS_OF_THE_DAY_LIST[diff];
  return (
    <div className="wotd">
      <h1 className="align-item-end">Word of the day</h1>
      <br />
      <h5>{todayDate}</h5>
      <Link to={"/word/" + wotd}>
        <h3 id="wotd-word">{wotd}</h3>
      </Link>
    </div>
  );
};

export default WordsOfTheDay;
