import React from "react";
import "./Footer.css";

const Footer = () => {
  const title = process.env.REACT_APP_APP_TITLE;
  const YEAR = new Date().getFullYear();
  return (
    <div className="footer">
      {/* <a target = "_blank" href="https://play.google.com/store/apps/details?id=com.zeeron.nepalidictionary&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
        <img
          alt="Get it on Google Play"
          src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
        />
      </a> */}
      <li><a href="/">About Us</a></li>
      <li><a href="/">Contact Us</a></li>
      <li><a href="/">Career</a></li>
      <li><a href="/">Feedbacks</a></li>
      <br />
      <h2> &copy;{YEAR} {title}</h2>
    </div>
  );
};

export default Footer;
