import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Autocomplete, TextField, Button } from "@mui/material";
import { debounce } from "lodash";
// import { InputAdornment } from "@mui/material";
import "./AutoSuggest.css";
import getAutoSuggestions from "./GetAutoSuggestions";

const AutoSuggest = ({}) => {
  const navigate = useNavigate();
  const [suggestions, setSuggestions] = useState([]);

  const [searchedWord, setSearchedWord] = useState("");

  const onSearchPressed = async (word) => {
    if (word.trim().length === 0) {
      return;
    }

    navigate("/word/" + word);
  };

  const getSuggestions = (word) => {
    if (word.trim().length === 0) {
      setSuggestions([]);
      return;
    }

    let suggestions = getAutoSuggestions(word.trim());
    setSuggestions(suggestions);
  };

  const debouncedGetSuggestions = debounce(getSuggestions, 250);

  return (
    <div className="searchContainer">
      <Autocomplete
        freeSolo={true}
        autoComplete
        disablePortal
        id="search-box"
        disableClearable
        options={suggestions}
        sx={{ width: 300 }}
        onChange={(event, newvalue) => {
          setSearchedWord(newvalue);
        }}
        onInputChange={(e, newInputValue) => {
          setSearchedWord(newInputValue);
          // getSuggestions(newInputValue);
          debouncedGetSuggestions(newInputValue);
        }}
        value={""}
        filterSelectedOptions
        includeInputInList
        filterOptions={(option, state) => option}
        renderInput={(params) => (
          <TextField
            {...params}
            label=""
            placeholder="Type here"
            onKeyDown={(key) => {
              if (key.code === "Enter") {
                onSearchPressed(searchedWord);
              }
            }}
            // InputProps={{
            //   endAdornment: (
            //     <InputAdornment>
            //       <IconButton
            //         onClick={() => {
            //         //   console.log("icon btn clicked");
            //           searchPressed();
            //         }}
            //       >
            //         <SearchIcon />
            //       </IconButton>
            //     </InputAdornment>
            //   ),
            // }}
          />
        )}
        getOptionLabel={(option) => {
          return typeof option === "string" ? option : option[0].toLowerCase();
        }}
      />
      <Button
        id="search-btn"
        onClick={() => {
          onSearchPressed(searchedWord);
        }}
      >
        Search
      </Button>
    </div>
  );
};

export default AutoSuggest;
