import React from "react";
import "./DownloadApp.css";
import play_store_img from "../play_store.png";
import app_store_img from "../app_store.png";
import ReactGA from "react-ga4";

const DownloadApp = () => {
  const DOWNLOAD_LINK_GOOGLE = process.env.REACT_APP_DOWLOAD_LINK_GOOGLE;
  const DOWNLOAD_LINK_IOS = process.env.REACT_APP_DOWNLOAD_LINK_IOS;

  const onPlayStoreClick = () =>{
    trackClick("play");
  }
  const onIosClick = () => {
    trackClick("ios")
  }

  const trackClick = (store_name) => {
    ReactGA.event({
      category: 'DownloadButton',
      action: 'Click',
      label: store_name
    });
  }
  return (
    <div className="download">
      <p>
        Download our android app to use the dictionary on your android mobile
        devices. It's an offline dictionary and you can use it anywhere anytime
        and don't need any internet connection.
      </p>
      <br />
      <p>
        The dictionary features more than 1,00,000 english words and has been
        downloaded more than 1 million times.
      </p>
      <br />
      <h3> {process.env.REACT_APP_DOWNLOAD_MSG}  </h3>
      <div className="download-imgs">
        <a
          target="_blank"
          href={DOWNLOAD_LINK_GOOGLE} onClick={onPlayStoreClick}
        >
          <img
            height="100vh"
            alt="Get it on Google Play"
            src={play_store_img}
          />
        </a>

        <a
          className="ios_a"
          target="_blank"
          href={DOWNLOAD_LINK_IOS} onClick={onIosClick}
        >
          <img
            className="ios_img"
            src={app_store_img}
            alt="Download on the App Store"
          />
        </a>
      </div>
    </div>
  );
};

export default DownloadApp;
