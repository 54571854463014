import { ratio } from "fuzzball";
import ALL_DICTIONARY_WORDS from "../dic";

const getSpellingSuggestions = (string_word) => {
  let suggestions = [];
  const MAX_SUGGESTIONS = 10;
  for (const word of ALL_DICTIONARY_WORDS) {
    if (ratio(string_word.toLowerCase(), word.toLowerCase()) >= 75) {
      suggestions.push(word);
      if (suggestions.length >= MAX_SUGGESTIONS) {
        break;
      }
    }
  }

  return suggestions;
};

export default getSpellingSuggestions;
