import React from "react";
import "./About.css";

const About = () => {
  const LANGUAGE = process.env.REACT_APP_LANGUAGE;
  return (
    <div className="about-container">
      <h1>About English to {LANGUAGE} Dictionary</h1>
      <hr />
      <p>
        English to {LANGUAGE} dictionary is a free online dictionary to quickly search {LANGUAGE.toLowerCase()} meanings of English words. 
      </p>
      <br />
      <p>
      Our English to {LANGUAGE} dictionary contains more than 1,00,000 unique english words. The dictionary contains root words, parts of speech, examples, pronunciation guide. 
      You can get auto suggestion when searching for your word and spelling suggestions if you mispell it. Bookmark our site to get new words of the day and enrich your vocabulary. We have worked hard to add make this site .
      <br/>
      <br/>
      This dictionary is constantly being updated with new words. We thrive to make this the number one website for english to {LANGUAGE.toLowerCase()} dictionary.
      </p>
     
      <br />
     
    </div>
  );
};

export default About;
