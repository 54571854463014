import React, {  useEffect, useState } from "react";
import "./Body.css";
import SpellingSuggestions from "./SpellingSuggestions";
import { Skeleton } from "@mui/material";
import { useParams } from "react-router-dom";
import axios from "axios";
import ALL_DICTIONARY_WORDS from "../dic";

let saveMeanings = new Map();

const Body = () => {
  const [meaning, setMeaning] = useState("");
  const [meaningNotFound, setMeaningNotFound] = useState(false);
  console.log(meaning);

  const { searchedWord } = useParams();

  const BACKEND_URL = process.env.REACT_APP_API_URL; 

  const getMeaning = async (word) => {
    if (word.trim().length === 0) {
      return null;
    }
    let meanings;
    if (saveMeanings.has(word)) {
      meanings = saveMeanings.get(word);
      // console.log("retriveing from cache");
    } else {
      let backend_url = BACKEND_URL + "/meaning/" + word;
      meanings = (await axios.get(backend_url)).data["meaning"];
      if (meanings !== null || meanings !== undefined) {
        saveMeanings.set(word, meanings);
      }
    }
    if (meanings === null || meanings === undefined) {
      return null;
    }

    let _meaning = {
      word: meanings[1],
      meaning: meanings[4],
      syn: meanings[5],
      ant: meanings[6],
      eng_meaning: meanings[3],
      pron: meanings[7],
    };
    return _meaning;
  };

  useEffect(() => {
    console.log("$serach word is " + searchedWord);

    const getResult = async () => {
      let result = await getMeaning(searchedWord);
      if (result === null || result === undefined) {
        setMeaning([]);
        setMeaningNotFound(true);
      } else {
        setMeaningNotFound(false);
        setMeaning(result);
        console.log(result);
      }
    };

    getResult();
  }, [searchedWord]);

  return (
    <div className="container">
      {/* {searchedWord} */}

      {meaning.length !== 0 ? (
        <>
          <div className="word-meaning">
            <h1 id="main-word">{meaning.word.toLowerCase()}</h1>
            <em>
              <h5 id="pron">/{meaning.pron}/</h5>
            </em>
          </div>

          <h3 id="meaning">{meaning.meaning}</h3>

          <h2
            style={{
              justifyContent: "left",
              textAlign: "left",
              paddingLeft: "2em",
            }}
            dangerouslySetInnerHTML={{ __html: meaning.eng_meaning }}
          ></h2>
          {meaning.syn && meaning.syn.length >= 0 && (
            <>
              <br />
              <h3 className="header3">Synonyms</h3>
              <div className="synonyms">
                {meaning.syn.split(",").map(function (d, idx) {
                  return (
                    <a href={d} key={idx}>
                      {d}
                    </a>
                  );
                })}
              </div>
            </>
          )}
          {meaning.ant && meaning.ant.length >= 0 && (
            <>
              <h3 className="header3">Antonyms</h3>
              <div className="antonyms">
                {meaning.ant.split(",").map(function (d, idx) {
                  return (
                    <a href={d} key={idx}>
                      {d}
                    </a>
                  );
                })}
              </div>
            </>
          )}
        </>
      ) : (
        <div>
          <h1 id="main-word">{searchedWord.toLowerCase()}</h1>
          <Skeleton width={150}></Skeleton>
          <Skeleton width={200}></Skeleton>
          <Skeleton width={400}></Skeleton>
        </div>
      )}
      {meaningNotFound && <SpellingSuggestions word={searchedWord} />}
    </div>
  );
};

export default Body;
